<template>
  <v-app>
    <ValidationErrors/>

    <v-navigation-drawer
      v-model="drawer"
      app
    >
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>{{ user.name }}</v-list-item-title>
          <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
          @click="$router.push(item.route)"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list-item
          link
          @click="logout"
        >
          <v-list-item-icon>
            <v-icon>mdi-export</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              Logout
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

    </v-navigation-drawer>

    <v-app-bar
      app
    >
      <v-app-bar-nav-icon
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title class="pr-5">{{ title }}</v-toolbar-title>
      <v-btn
          v-if="project"
          color="primary"
          target="_blank"
          outlined
          :href="`https://app.grundriss-butler.de/admin/floorplans/?job_id=${project.job_id}`"
      >
        <v-icon left>mdi-open-in-new</v-icon>
        Goto gb-admin
      </v-btn>

      <v-spacer></v-spacer>

      <slot name="app-bar"></slot>

      <v-btn
        v-if="project"
        color="primary"
        @click="$router.push(`/project-form/${project.job_id}`)"
      >
        <v-icon left>mdi-pencil</v-icon>
        Edit
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
        <slot/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import ValidationErrors from '../components/ValidationErrors'
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'AdminLayout',

  metaInfo () {
    return {
      title: this.title,
      titleTemplate: '%s | Revision Tool',
    }
  },

  props: {
    title: String,
    project: Object,
  },

  components: {
    ValidationErrors,
  },

  data: () => ({
    drawer: false,
    items: [
      {
        title: 'Dashboard',
        icon: 'mdi-view-dashboard',
        route: '/',
      },
      {
        title: 'Create Project',
        icon: 'mdi-plus',
        route: '/project-form',
      },
      {
        title: 'Notification Emails',
        icon: 'mdi-email',
        route: '/notification-email',
      },
      {
        title: 'Notes',
        icon: 'mdi-notebook',
        route: '/notes',
      },
      {
        title: 'Settings',
        icon: 'mdi-cog',
        route: '/settings',
      },
    ],
  }),

  computed: {
    ...mapGetters('user', ['user']),
  },

  methods: {
    ...mapMutations('user', ['unsetUserData']),

    logout () {
      this.unsetUserData()
      this.$router.push('/login')
    },
  },
}
</script>
